import { Address, PropertyListing } from '@unreserved-frontend-v2/api/generated/graphql/types'

import { DEFAULT_LISTING_DIRECTORY } from './constants'

export interface ListingData {
  id: string
  property: {
    address: {
      slug?: string | null
      address1?: string | null
    }
  }
}

export function getSluggedListingData(listingId: string, address?: Address) {
  return {
    id: listingId,
    property: {
      address: {
        slug: address?.slug,
        address1: address?.address1,
      },
    },
  }
}

/**
 * @deprecated use PropertyListing related classes
 * Formatting method that generates a unique URL based off of the listing info
 */
export function formatListingDetailsUrl(listing?: ListingData | null) {
  if (!listing) {
    return ''
  }

  const {
    id,
    property: { address },
  } = listing

  return `/${DEFAULT_LISTING_DIRECTORY}/listing/${
    address.slug || address?.address1?.toLowerCase().replace(/ |#/g, '-')
  }-id${id}`
}

/**
 * Formatting method that generates a unique URL based off of the listing info
 */
export function formatPropertyListingDetailsUrl(listing?: PropertyListing | null) {
  if (!listing) {
    return ''
  }

  const {
    id,
    currentIteration: { address },
  } = listing

  return `/${DEFAULT_LISTING_DIRECTORY}/listing/${
    address.slug || address?.address1?.toLowerCase().replace(/ |#/g, '-')
  }-id${id}`
}

// Utility method to separate the `id` from the combined `${addressSlug}-id${id}` in `formatListingDetailsUrl`
export function parseListingDetailsId(addressIdSlug: string): string | undefined {
  return addressIdSlug.split('-id').pop()
}
