import { differenceInDays } from 'date-fns'

import {
  PropertyListing,
  PropertyListingStatus,
  ResidentialPropertyForSaleListing,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { getSimpleDate, massageDate } from '@unreserved-frontend-v2/utils/date-formatters'

/**
 * @deprecated use PropertyListing related classes
 */
export const getDaysOnMarket = (listing?: ResidentialPropertyForSaleListing | null) => {
  let dateSoldOrPresent = getSimpleDate()

  if (listing?.listingStatus === PropertyListingStatus.Sold) {
    // If listing is sold but no dateSold is available, cannot calculate D.O.M
    if (!listing.dateSold) {
      return undefined
    } else {
      dateSoldOrPresent = massageDate(listing.dateSold)
    }
  }

  // cannot calculate D.O.M without a dateListed
  return listing?.dateListed
    ? Math.max(
        differenceInDays(massageDate(dateSoldOrPresent.toISOString().split('T')[0]), massageDate(listing.dateListed)),
        0
      )
    : undefined
}

/**
 * @deprecated use PropertyListing related classes
 */
export const getDaysOnMarketMls = (listing?: PropertyListing | null) => {
  if (!listing || !listing.listedAt) {
    return undefined
  }

  const listedAt = getSimpleDate(listing.listedAt)
  const offMarketDate = listing.latestIteration.offMarketAt ? getSimpleDate(listing.latestIteration.offMarketAt) : null

  return listedAt && offMarketDate
    ? Math.max(differenceInDays(offMarketDate, listedAt), 0)
    : Math.max(differenceInDays(getSimpleDate(), listedAt), 0)
}
