import merge from 'lodash/merge'
import { useContext, useMemo } from 'react'

import { ResidentialPropertyForSaleListing } from '@unreserved-frontend-v2/api/generated/graphql/types'

import { LISTING } from '../fixtures/listing'
import { UserContext } from '../../users/components/user-info-provider/user-context'

export type UseRestrictedListingResult = {
  isRestricted: true | undefined
  // TODO: [#1612] Make this optional to properly reflect that it can be undefined/nullable
  data: ResidentialPropertyForSaleListing
}

/**
 * @deprecated use PropertyListing related classes
 */
export const useRestrictedListing = (listing: ResidentialPropertyForSaleListing): UseRestrictedListingResult => {
  const { hasToken } = useContext(UserContext)

  const parsedData = useMemo<ResidentialPropertyForSaleListing>(() => {
    if (!listing?.mlsDetails?.requiresRegistration || hasToken) {
      return listing
    }

    return merge({}, listing, LISTING)
  }, [hasToken, listing])

  return useMemo(
    () => ({
      /**
       * Only true/undefined, no false because data-nosnippet="false" still counts as truthy by SEO
       * https://developers.google.com/search/docs/crawling-indexing/robots-meta-tag?hl=en#data-nosnippet-attr
       */
      isRestricted: (!hasToken && listing?.mlsDetails?.requiresRegistration) || undefined,
      data: parsedData,
    }),
    [hasToken, listing?.mlsDetails?.requiresRegistration, parsedData]
  )
}
